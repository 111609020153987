import { Box, ListItemButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Icon, iconCircleCheck, iconCircleXmarkSolid, iconArrowRightRegular } from "theme/icons";
import { Link, useLocation } from "react-router-dom";
import { ItemTestResultItem } from "services/reportingService";
import { useDateService } from "hooks/useDateService";
import { styleTabContent } from "./allTestResults";
import { useAppIntl } from "services/useAppIntl";
import FormattedMessage from "utils/helpers/FormattedMessage";

type Props = {
  testResults: ItemTestResultItem[];
};

export const FinalTest = ({ testResults }: Props) => {
  const theme = useTheme();
  const intl = useAppIntl();
  const location = useLocation();
  const { getDateWithLocalTime: getDateWithTime, getDuration } = useDateService();

  return (
    <>
      {testResults
        .map((result, i) => {
          const { value, max, duration, finished, success } = result;
          const testDuration = duration
            ? getDuration(duration)
            : intl.formatMessage({ id: "test.create.custom.dropdown.duration" });
          const statusIcon = success ? (
            <Icon name={iconCircleCheck} htmlColor={theme.palette.success.main} />
          ) : (
            <Icon name={iconCircleXmarkSolid} htmlColor={theme.palette.error.main} />
          );

          return (
            <ListItemButton
              key={i}
              sx={styleTabContent}
              component={Link}
              to={"final/" + result.statementRef}
              state={{ from: location }}
            >
              <Box display="flex" alignItems={"center"}>
                <Box display={"flex"} width="6rem" alignItems={"center"}>
                  {statusIcon}
                  <Typography variant="body2" ml={1} fontWeight={600}>
                    {value}/{max}
                  </Typography>
                </Box>
                <Typography variant="body2" display={"flex"} textAlign={"left"}>
                  <FormattedMessage id={success ? "common.title.passed" : "common.title.failed"} />
                </Typography>
              </Box>
              <Box display={"flex"} textAlign={"right"} alignItems={"center"}>
                <Box display={"flex"} flexDirection={"column"} mr={2}>
                  <Typography variant="body2">{testDuration}</Typography>
                  <Typography variant="caption">{getDateWithTime(finished)}</Typography>
                </Box>
                <Icon name={iconArrowRightRegular} color="info" />
              </Box>
            </ListItemButton>
          );
        })
        .reverse()}
    </>
  );
};
