import { useEffect, useState } from "react";
import { Stack, Button, Switch, FormControlLabel, FormGroup, ButtonGroup, Box, Typography } from "@mui/material";
import { Icon, iconLockOpen, iconMinus, iconPlus } from "theme/icons";
import { styleFlexJustifyContentEnd } from "theme/styles";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { ItemAssessment } from "@strmediaochitab/optima-component-library";
import { useAssessment } from "hooks/useAssessment";
import { TestSettings, useTestSettings } from "hooks/useTestSettings";
import { useRecoilValue } from "recoil";
import { XapiKey } from "services/lrsService";
import { educationContentTypeState } from "state/learningStructureState";
import { educationStateReferenceKey } from "state/educationState";
import { Test, TestLabel } from "../test";
import { useFinalTestStatus } from "hooks/useFinalTestStatus";
import { FinalTestDialog } from "./finalTestDialog";
import { InfoMessage } from "utils/helpers/InfoMessage";
import { NotFound } from "layout/notFound";
import { userStateAccount } from "state/userState";

export const TestFinal = () => {
  const account = useRecoilValue(userStateAccount);
  const { finalTestUnlocked } = useFinalTestStatus();
  const { finalTest } = useTestSettings();
  const [test, setTest] = useState<ItemAssessment | undefined>();
  const [testSettings, setTestSettings] = useState<TestSettings>(finalTest);
  const [openFinalTestDialog, setOpenFinalTestDialog] = useState(false);
  const referenceKey = useRecoilValue(educationStateReferenceKey);
  const { subjectCategories, assessment } = useAssessment({
    type: testSettings.questionType,
    key: referenceKey!,
  });

  const xapiKey: XapiKey = {
    userId: account!.actorId,
    contentId: referenceKey!.contentId,
    versionId: referenceKey!.versionId!,
    stateId: useRecoilValue(educationContentTypeState).get(testSettings.questionType)!,
  };

  // When subjects resolved set them to state
  useEffect(() => {
    if (!subjectCategories) return;

    let subjects = new Set<string>();
    subjectCategories.forEach((item) => subjects.add(item.id!));

    setTestSettings((settings) => ({ ...settings, ...finalTest, subjects }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectCategories, finalTest]);

  const handleStartTest = () => {
    if (!assessment) return console.error("No assessment loaded");
    if (!subjectCategories) return console.error("No subject categories loaded");
    if (!finalTestUnlocked) return setOpenFinalTestDialog(true);

    const test = {
      ...assessment,
      configuration: {
        ...assessment.configuration,
        duration: testSettings.duration,
        quantity: testSettings.questions,
        treshold: testSettings.ratio || testSettings.questions, // Get from settings here to calculate success on finalize assessment
      },
    };

    console.log("startar test med följande params", testSettings, test);
    setTest(test);
  };

  const handleSetDuration = (duration?: number) => {
    setTestSettings({ ...testSettings, duration });
  };

  if (!account || finalTestUnlocked === undefined) return null;
  if (assessment === null) return <NotFound />;

  // Start the test
  if (test) return <Test label={TestLabel.Final} assessment={test} xapiKey={xapiKey} userId={account.actorId} />;

  return (
    <>
      <FormattedTypography id="common.title.test.final" variant="h1" mb={4} />

      {!finalTestUnlocked && (
        <Box mb={2}>
          <InfoMessage message="test.final.info" type="error" />
        </Box>
      )}

      <Stack spacing={4}>
        <FormattedTypography
          id="test.final.intro"
          values={{
            // Used for newlines in text
            br: (
              <>
                <br />
                <br />
              </>
            ),
          }}
        />

        <TestTime duration={testSettings.duration} onSetDuration={handleSetDuration} />

        <Button
          variant="contained"
          fullWidth
          onClick={handleStartTest}
          color={!finalTestUnlocked ? "error" : "primary"}
        >
          {!finalTestUnlocked ? (
            <>
              <Icon name={iconLockOpen} />
              <FormattedTypography id="test.final.dialog.button" variant="inherit" ml={0.5} />
            </>
          ) : (
            <>
              <FormattedTypography id="test.start" variant="inherit" mr={0.5} />
              <FormattedTypography id="test.start.final" variant="inherit" />
            </>
          )}
        </Button>
      </Stack>

      <FinalTestDialog open={openFinalTestDialog} close={() => setOpenFinalTestDialog(false)} />
    </>
  );
};

interface ITestTime {
  duration?: number;
  onSetDuration: (duration?: number) => void;
}
const TestTime = ({ duration, onSetDuration }: ITestTime) => {
  const [timeLimit, setTimeLimit] = useState(true);
  const minValue = 10;
  const maxValue = 70;

  const toggleTimeLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeLimit(event.target.checked);

    onSetDuration(event.target.checked ? 50 : undefined);
  };

  const handleSetTime = (value: number) => {
    if (value < 0 && duration === minValue) return;
    if (value > 0 && duration === maxValue) return;

    onSetDuration(duration ? duration + value : duration);
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={timeLimit} onChange={toggleTimeLimit} />}
          label={<FormattedTypography id="test.final.time-limit" variant="subtitle1" />}
          labelPlacement="start"
          sx={[styleFlexJustifyContentEnd, { ml: 0 }]}
        />
      </FormGroup>

      <ButtonGroup variant="outlined" disabled={!timeLimit}>
        <Button onClick={() => handleSetTime(-10)}>
          <Icon name={iconMinus} />
        </Button>
        <Box
          sx={{
            width: "50%",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <Typography color="primary" variant="button" sx={{ height: "auto" }}>
            {timeLimit ? duration : null}
          </Typography>
        </Box>
        <Button onClick={() => handleSetTime(10)}>
          <Icon name={iconPlus} />
        </Button>
      </ButtonGroup>
    </>
  );
};
