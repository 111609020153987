import { useState } from "react";
import { Icon, iconAward } from "theme/icons";
import { Box, Stack, Typography } from "@mui/material";
import Status from "./Status";
import ItemContainer from "./ItemContainer";
import Comment, { AddComment } from "./Comment/Comment";
import useRoles from "hooks/useRoles";
import { LoadingStatus } from "types/request";
import { useRecoilValue } from "recoil";
import { educationCardPropsState } from "state/educationCardState";
import { EducationCardActivityTest } from "types/educationCard";
import AccordionEducationCard from "layout/Components/AccordionEducationCard";
import Students from "./students";
import { useTestResult } from "hooks/useTestResult";
import { ItemConfigurationTypeKnowledge } from "services/reportingService";
import { useDateService } from "hooks/useDateService";
import LearningObjectiveHeader from "./LearningObjectiveHeader";
import { ReferenceKey } from "@strmediaochitab/optima-component-library";
import useFinalTestInfo from "./useFinalTestForEducator";
import FormSwitch from "utils/helpers/FormSwitch";

const theoreticalTest: EducationCardActivityTest = {
  id: "1",
  referenceKey: { contentId: "1", versionId: "1" },
  parentKey: { contentId: "1", versionId: "1" },
  rootKey: { contentId: "1", versionId: "1" },
  title: "Teoretiskt prov",
  approved: false,
  status: "None",
  finalTestUnlocked: false,
  finalTestApproved: false,
  // studentStatus: new Map([
  //   ["student-1-guid", { status: "None", approved: false, finalTestUnlocked: false }],
  //   ["student-2-guid", { status: "None", approved: false, finalTestUnlocked: true }],
  // ]),
};

export type TestSwitchProps = {
  userIds: string[];
  label?: string;
  testId?: { educationKey: { contentId: string; versionId: string }; stateId: string };
  assessmentKey?: ReferenceKey;
  onBeforeChange?: () => void;
  onAfterChange?: () => void;
};

const ItemTestTheoretical = () => {
  // TEMP hardcoded data for now...
  const item = theoreticalTest;

  const [status, setStatus] = useState<LoadingStatus>("none");
  const { isEducator } = useRoles();
  const state = useRecoilValue(educationCardPropsState);

  if (!state) throw new Error("No education card state found");

  const { finalTestUnlocked, finalTestApproved, finalTestSuccess, handleApprove, handleUnlock } = useFinalTestInfo(
    state.userIds
  );

  // console.log("test status for", state.userIds, finalTestUnlocked, finalTestApproved, finalTestSuccess);

  return (
    <AccordionEducationCard
      id={item.id}
      status={status}
      summary={
        <ItemContainer>
          <Stack spacing={1} direction="row" alignItems="center">
            <Icon name={iconAward} color="info" />
            <Typography variant="subtitle2">{item.title}</Typography>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            {isEducator ? (
              <>
                {/* TODO: Förbättra hantering av success status */}
                <Status status={finalTestSuccess ? "Success" : "Fail"} />
                <FormSwitch label="Godkänd" checked={finalTestApproved} onChange={handleApprove} />
                <FormSwitch label="Upplåst" checked={finalTestUnlocked} onChange={handleUnlock} />
                {state.type !== "education" && <Comment hasComment={false} noButton />}
              </>
            ) : (
              <></>
              // Hide this until we have correct data...
              // <Status status={item.status} />
            )}
            {state.type === "education" && <Students item={item} />}
          </Stack>
        </ItemContainer>
      }
      details={state.type !== "education" && <GetTestResults userId={state.userIds[0]} item={item} />}
    />
  );
};

export default ItemTestTheoretical;

const GetTestResults = ({ userId, item }: { userId: string; item: EducationCardActivityTest }) => {
  const { testResults } = useTestResult({ actorId: userId });
  const { getLocalDate, getDuration } = useDateService();

  // TODO: Vad returnera?
  if (testResults === undefined) return null;

  const finalTests = testResults.filter(
    (result) => result.configuration.name === ItemConfigurationTypeKnowledge.FinalA
  );

  console.log("finalTests", finalTests);

  return (
    <Stack spacing={2}>
      <LearningObjectiveHeader type={"test"} tempProp="student" />

      {finalTests.map((result, i) => {
        const { finished, success } = result;
        const status = success ? "Success" : "Fail";

        return (
          <ItemContainer key={result.statementRef}>
            <Stack direction={"row"} flexGrow={1} display="flex" alignItems="center">
              <Typography variant="subtitle2">{getLocalDate(finished)}</Typography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="subtitle2" width={50} pr={1} textAlign={"right"}>
                {result.value}/{result.max}
              </Typography>
              |
              <Typography variant="subtitle2" width={40}>
                {getDuration(result.duration ?? 0)}
              </Typography>
              <Status status={status} />
              <AddComment comments={item.comments} title={item.title} />
            </Stack>
          </ItemContainer>
        );
      })}
    </Stack>
  );
};
