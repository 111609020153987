import { ReactNode, FC } from "react";
import { AppBar, Box, Toolbar } from "@mui/material";
import { useRecoilValue } from "recoil";
import { theoryAreaState, theoryChapterState } from "state/theoryState";
import { HeaderNavigation } from "utils/helpers/HeaderNavigation";
import { useNavigate, useParams } from "react-router-dom";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { HeaderDesktopNavigation } from "../headerDesktopNavigation";
import useMediaQueries from "hooks/useMediaQueries";
interface IHeaderProps {
  children?: ReactNode;
}

export const Header: FC<IHeaderProps> = ({ children }) => {
  const { areaId, chapterId } = useParams();
  const area = useRecoilValue(theoryAreaState);
  const chapter = useRecoilValue(theoryChapterState);
  const title = chapterId && chapter ? chapter.title : area?.title;
  const topTitle = chapterId ? `/ ${area?.title}` : "";
  const routes = useRouteConfiguration();
  const { isDesktop } = useMediaQueries();

  // const [showSideMenu, setShowSideMenu] = useState(false);

  const navigate = useNavigate();

  const navigateBack = () => {
    if (!areaId) return navigate(-1);

    // Navigate back up in the tree
    let path = chapterId ? routes.theoryChapters.path.replace(":areaId", areaId!) : routes.theory.path;

    navigate(path);
  };

  return (
    <AppBar position="fixed" className="AppBar-top">
      <Toolbar>
        <HeaderNavigation
          iconSize={"large"}
          topTitle={`Teori ${topTitle}`}
          title={title ? title : ""}
          onNavigate={navigateBack}
        />
        {isDesktop && (
          <>
            <HeaderDesktopNavigation />
            {/* <Box width="30%" textAlign="right">
              {isDesktop && (
                <>
                  <IconButton color="success" onClick={() => setShowSideMenu(!showSideMenu)}>
                    <Icon name={iconMenu} />
                  </IconButton>
                  <SideMenu showSideMenu={showSideMenu} setShowSideMenu={setShowSideMenu} />
                </>
              )}
            </Box> */}
          </>
        )}
        <Box width="30%" />
      </Toolbar>
      {children}
    </AppBar>
  );
};
