import { Route, Routes } from "react-router-dom";
import { NotFound } from "layout/notFound";
import { DefaultLayout, TheoryLayout, TestLayout, EducatorLayout, TheoryChaptersLayout } from "layout";
import { Unauthorized } from "layout/unauthorized";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { Box } from "@mui/material";
import { ResetTest } from "layout/test/testResult";
import { ProtectedRoutes } from "./protectedRoutes";
import { Start } from "layout/start";
import { HeaderNavigationTabs } from "layout/educator/HeaderNavigationTabs";
import { Logout } from "layout/logout";

const EducatorRoutes = () => {
  const routes = useRouteConfiguration();

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route element={<DefaultLayout hideEducations hideFooter />}>
          <Route index element={routes.educator.component} />
          <Route path={routes.theory.path} element={routes.theory.component} />
          <Route path={routes.test.path} element={routes.test.component} />
          <Route path={routes.more.path} element={routes.more.component} />
          <Route path={routes.progress.path} element={routes.progress.component} />

          <Route path="*" element={<NotFound />} />
        </Route>

        <Route element={<EducatorLayout />}>
          <Route
            path={routes.educator.routes!.educationcard.path}
            element={routes.educator.routes!.educationcard.component}
          />
          <Route
            path={routes.educator.routes!.studentAdd.path}
            element={routes.educator.routes!.studentAdd.component}
          />
          <Route
            path={routes.educator.routes!.studentEdit.path}
            element={routes.educator.routes!.studentEdit.component}
          />
          <Route
            path={routes.educator.routes!.studentEducationcard.path}
            element={routes.educator.routes!.studentEducationcard.component}
          />
          <Route
            path={routes.educator.routes!.studentEducationcardView.path}
            element={routes.educator.routes!.studentEducationcardView.component}
          />
        </Route>
        <Route element={<EducatorLayout tabs={<HeaderNavigationTabs />} />}>
          <Route path={routes.educator.routes!.group.path} element={routes.educator.routes!.group.component} />
          <Route path={routes.educator.routes!.students.path} element={routes.educator.routes!.students.component} />
        </Route>

        <Route element={<TheoryLayout />}>
          <Route path={routes.theoryRead.path} element={routes.theoryRead.component} />
          <Route path={routes.theoryPractice.path} element={routes.theoryPractice.component} />
          <Route path={routes.theoryAnswer.path} element={routes.theoryAnswer.component} />
        </Route>

        <Route element={<TheoryChaptersLayout />}>
          <Route path={routes.theoryChapters.path} element={routes.theoryChapters.component} />
        </Route>

        <Route element={<TestLayout />}>
          {Object.entries(routes.test.routes!).map(([key, route]) => (
            <Route key={key} path={`${routes.test.path}/${route.path}`} element={route.component} />
          ))}
          {Object.entries(routes.progress.routes!).map(([key, route]) => (
            <Route key={key} path={`${routes.progress.path}/${route.path}`} element={route.component} />
          ))}
        </Route>
      </Route>

      <Route path="/start" element={<Start />} />
      <Route path="/resetTest" element={<ResetTest />} />
      <Route path="/auth-redirect" element={<Box />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
};

export default EducatorRoutes;
