import { FeatureFlagResponse } from "@strmediaochitab/optima-react-components";
import { get } from "services/httpService";
import { API_URL_FFS } from "utils/constants";
import { msalInstance } from "utils/helpers/msal/AppMsalProvider";

export const fetchFeatureFlags = async (): Promise<FeatureFlagResponse[]> => {
  const account = msalInstance.getActiveAccount();

  // Check to see if we have an account (eg. user is logged in), if not, retry
  if (!account) throw new Error("No active account found");

  return await get(API_URL_FFS);
};

export const fetchFeatureFlag = async (flag: string): Promise<FeatureFlagResponse> => {
  return await get(API_URL_FFS + "/" + flag);
};
