import { useState } from "react";
import { Avatar, Stack, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { useRecoilValue } from "recoil";
import { userStateAccount } from "state/userState";
import { Icon, iconLogout } from "theme/icons";
import useRoles from "hooks/useRoles";
import { useLogout } from "hooks/useLogout";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { getAppLogo } from "./AppTitle";

const UserInfo = () => {
  const userAccount = useRecoilValue(userStateAccount);
  const { isEducator } = useRoles();
  const logo = getAppLogo();

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Stack alignItems={"end"}>
        <Typography variant="subtitle1" fontSize={12}>
          {userAccount?.name}
        </Typography>
        <Typography variant="captionImage">{isEducator ? "Instruktör" : "Förare"}</Typography>
      </Stack>
      <Avatar src={logo} variant="rounded" sx={{ backgroundColor: "#FFFFFF", "> img": { objectFit: "scale-down" } }} />
      {/* <UserMenu /> */}
    </Stack>
  );
};

export default UserInfo;

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { logout } = useLogout();
  const logo = getAppLogo();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="menu actions for current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Avatar
          src={logo}
          variant="rounded"
          sx={{ backgroundColor: "#FFFFFF", "> img": { objectFit: "scale-down" } }}
        />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          sx: { minWidth: "160px" },
        }}
      >
        <MenuItem onClick={async () => await logout()} sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormattedMessage id="common.logout" /> <Icon name={iconLogout} color="secondary" />
        </MenuItem>
      </Menu>
    </>
  );
};
