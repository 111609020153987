import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { BasicLayout } from "layout";

type AppIntroProps = {
  text?: string;
};
export const AppIntro = ({ text }: AppIntroProps) => {
  // Don't have access to appContext here, so using window.env.APP_THEME instead
  const themeName = window.env.APP_THEME;

  const logo = themeName === "FM" ? "/img/logoFM.png" : "/img/logoFlex.png";
  const size = themeName === "FM" ? { w: "172px", h: "70px" } : { w: "auto", h: "70px" };
  const textColor = themeName === "FM" ? "#393E40" : "#393E40";

  return (
    <BasicLayout paddingBottom={0} paddingLeft={0} paddingRight={0} paddingTop={0}>
      <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" px="2rem" textAlign="center">
        <img
          src={process.env.PUBLIC_URL + logo}
          alt="Logo"
          style={{ width: size.w, height: size.h, marginLeft: "auto", marginRight: "auto" }}
        />
        <Typography variant="h1" fontSize="24px" color={textColor}>
          {text}
        </Typography>
      </Box>
    </BasicLayout>
  );
};
