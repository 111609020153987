import { Statement, Activity, Extensions } from "@xapi/xapi";
import {
  Item,
  ItemAssessment,
  ItemAssessmentGroup,
  ItemQuestion,
  ItemText,
  ItemType,
  LRSProviderState,
  LRSRecord,
  ReferenceKey,
} from "@strmediaochitab/optima-component-library";
import { getXapiStatement, uriHost } from "./xapiService";
import { TestLabel } from "layout/test/test";
import { API_URL_CDS_COMPOSITE } from "utils/constants";
import { post } from "./httpService";
import { LearningObjectives } from "types/cds";

const apiCompositeUrl = API_URL_CDS_COMPOSITE;

export type ItemAssessmentGroupExtended = ItemAssessmentGroup & { learningObjectiveMap: Map<string, ReferenceKey[]> };

export enum ItemTypeKnowledge {
  None = "None",
  Knowledge = "Knowledge",
  KnowledgeLevel = "KnowledgeLevel",
  TestResults = "TestResults",
}
/**
 * Enum representing different types of item configurations for knowledge reporting.
 *
 * @property {string} None - No specific configuration.
 * @property {string} KnowledgeA - Configuration for Knowledge A.
 * @property {string} OverviewA - Configuration for Overview A.
 * @property {string} DetailA - Configuration for detailed progress per chapter.
 * @property {string} DetailB - Configuration for detailed progress per activity.
 * @property {string} StudyA - Configuration for Study A.
 * @property {string} FinalA - Configuration for Final A.
 */
export enum ItemConfigurationTypeKnowledge {
  None = "None",
  KnowledgeA = "KnowledgeA",
  OverviewA = "OverviewA",
  DetailA = "DetailA", // Progress per chapter
  DetailB = "DetailB", // Progress per activity
  StudyA = "StudyA",
  FinalA = "FinalA",
}

export type ItemKnowledge = {
  type: ItemTypeKnowledge;
  configuration: {
    type: ItemConfigurationTypeKnowledge;
  };
  items: ItemKnowledgeOverview[] | ItemKnowledgeDetail[] | ItemTestResult[];
};
export type ItemKnowledgeOverview = {
  type: ItemTypeKnowledge;
  configuration: {
    type: ItemConfigurationTypeKnowledge;
  };
  items: ItemTitleValuePair[];
};
export type ItemKnowledgeDetail = {
  type: ItemTypeKnowledge;
  configuration: {
    type: ItemConfigurationTypeKnowledge;
  };
  items: ItemTitleValuePair[];
};

export type ItemTestResults = {
  type: ItemTypeKnowledge.TestResults;
  configuration: {
    type: ItemConfigurationTypeKnowledge;
  };
  items: ItemTestResultItem[];
};

export type ItemTestResult = {
  type: ItemTypeKnowledge;
  configuration: {
    type: ItemConfigurationTypeKnowledge;
  };
  items: ItemTestResultItem[];
};
export type ItemTitleValuePair = {
  type: ItemTypeKnowledge;
  configuration: {
    type: ItemConfigurationTypeKnowledge;
  };
  title: string;
  value: string;
};
export type ItemTestResultItem = {
  type: ItemTypeKnowledge;
  configuration: {
    type: ItemConfigurationTypeKnowledge;
    name?: ItemConfigurationTypeKnowledge;
  };
  statementRef: string;
  title: TestLabel;
  value: string;
  duration?: number;
  finished: string;
  max: string;
  success: boolean;
};

// Temp type until we get this shit sorted out
export type ItemQuestionExtended = ItemQuestion & { learningObjectives: LearningObjectives };
export type ResultOverview = {
  referenceKey?: ReferenceKey; // Kan den vara undefined?
  question?: ItemQuestionExtended;
  result: Statement["result"];
  correctResponse: string;
  optionOrder: string;
  title: string;
};

const getProgressedLearningObjectivesByQuestions = (
  master: ItemAssessment,
  assessmentState: LRSProviderState,
  questionState: LRSRecord,
  label: TestLabel
) => {
  const groups = master.items as ItemAssessmentGroupExtended[];
  const maps = groups.map((x) => x.learningObjectiveMap);
  let questions: any[] = [];

  assessmentState.questions?.forEach((q) => {
    let learningObjectives: string[] = [];

    const qState = questionState.get(q.versionId);

    if (qState) {
      if (qState.result?.success) {
        maps.forEach((map) => {
          for (let [key, value] of map) {
            if (value.map((x) => x.versionId).includes(q.versionId)) learningObjectives = [...learningObjectives, key];
          }
        });
      }
      questions = [
        ...questions,
        { referenceKey: qState.referenceKey, statementRef: qState.id, learningObjectives: learningObjectives },
      ];
    } else {
      questions = [
        ...questions,
        {
          referenceKey: { contentId: q.contentId, versionId: q.versionId },
          statementRef: null,
          learningObjectives: learningObjectives,
        },
      ];
    }
  });

  return { questions, label };
};

const createProgressReport = (master: ItemAssessment, statements: Statement[]): ItemKnowledge => {
  const set = new Set<string>();

  statements.forEach((s) => {
    const activity = s.object as Activity;
    const ids: string[] = activity.definition?.extensions![`http://${uriHost}/learningObjective/id`];
    ids.forEach((id) => set.add(id));
  });

  const groups = master.items as ItemAssessmentGroupExtended[];

  const map = new Map<string, Set<string>>();

  groups.forEach((group) => {
    for (let [key] of group.learningObjectiveMap) {
      if (Array.from(set.values()).includes(key)) {
        if (!map.has(group.id!)) map.set(group.id!, new Set());
        map.get(group.id!)?.add(key);
      }
    }
  });

  const itemKnowledgeOverview: ItemKnowledgeOverview = {
    type: ItemTypeKnowledge.KnowledgeLevel,
    configuration: { type: ItemConfigurationTypeKnowledge.OverviewA },
    items: [],
  };
  const itemKnowledgeDetail: ItemKnowledgeDetail = {
    type: ItemTypeKnowledge.KnowledgeLevel,
    configuration: {
      type: ItemConfigurationTypeKnowledge.DetailA,
    },
    items: [],
  };
  const itemKnowledge: ItemKnowledge = {
    type: ItemTypeKnowledge.Knowledge,
    configuration: {
      type: ItemConfigurationTypeKnowledge.KnowledgeA,
    },
    items: [itemKnowledgeOverview, itemKnowledgeDetail],
  };

  const a = new Set(Array.from(groups.values()).flatMap((x) => x.learningObjectives)).size;
  const b = Array.from(map.values())
    .map((x) => x.size)
    .reduce((a, b) => a + b, 0);
  const total = Math.round((b / a) * 100);

  itemKnowledgeOverview.items = [
    ...itemKnowledgeOverview.items,
    {
      type: ItemTypeKnowledge.None,
      configuration: { type: ItemConfigurationTypeKnowledge.None },
      title: "",
      value: total.toString(),
    },
  ];

  groups.forEach((group) => {
    const value = map.has(group.id!)
      ? Math.round((map.get(group.id!)?.size! / group.learningObjectives.length) * 100)
      : 0;
    itemKnowledgeDetail.items = [
      ...itemKnowledgeDetail.items,
      {
        type: ItemTypeKnowledge.None,
        configuration: { type: ItemConfigurationTypeKnowledge.None },
        title: group.name!,
        value: value.toString(),
      },
    ];
  });

  return itemKnowledge;
};

const createTestReport = (statements: Statement[]): ItemKnowledge => {
  const itemTestResult = createTestResults(statements);
  const itemKnowledge: ItemKnowledge = {
    type: ItemTypeKnowledge.Knowledge,
    configuration: {
      type: ItemConfigurationTypeKnowledge.KnowledgeA,
    },
    items: [itemTestResult],
  };
  return itemKnowledge;
};

const createTestResults = (statements: Statement[]): ItemTestResult => {
  const itemTestResult: ItemTestResult = {
    type: ItemTypeKnowledge.TestResults,
    configuration: {
      type: ItemConfigurationTypeKnowledge.OverviewA,
    },
    items: [],
  };

  const getMillisecs = (duration: string) => {
    if (!duration.includes("PT")) {
      if (duration.length === 0) return;
      const [hours, minutes, seconds] = duration.split(":").map(Number);
      return hours * 3600000 + minutes * 60000 + seconds * 1000;
    }

    const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
    const matches = duration.match(regex);

    if (!matches) {
      throw new Error("Invalid ISO 8601 duration format");
    }

    const hours = matches[1] ? parseInt(matches[1], 10) : 0;
    const minutes = matches[2] ? parseInt(matches[2], 10) : 0;
    const seconds = matches[3] ? parseInt(matches[3], 10) : 0;

    const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
    return totalMilliseconds;
  };

  statements.forEach((statement) => {
    const activity = statement.object as Activity;
    // Move to separate method
    const assessment: any = activity.definition?.extensions![`http://${uriHost}/assessment`]
      ? activity.definition?.extensions![`http://${uriHost}/assessment`]
      : undefined;
    const assessmentLabel: TestLabel = assessment?.label;

    const duration = statement.result?.duration ? getMillisecs(statement.result?.duration) : undefined;

    itemTestResult.items = [
      ...itemTestResult.items,
      {
        type: ItemTypeKnowledge.TestResults,
        configuration: {
          type:
            assessmentLabel === "f59603d5-a42d-48cd-9cc8-ea225aec9e98"
              ? ItemConfigurationTypeKnowledge.FinalA
              : ItemConfigurationTypeKnowledge.StudyA,
        },
        value: statement.result?.score?.raw?.toString()!,
        statementRef: statement.id!,
        title: assessmentLabel,
        duration: duration,
        max: statement.result?.score?.max?.toString()!,
        finished: statement.timestamp?.toString()!,
        success: statement.result?.success ?? false,
      },
    ];
  });

  return itemTestResult;
};

const getResultOverview = async (id: string) => {
  const statement: Statement | null = await getXapiStatement(id);
  if (statement === null) return [];

  const activity = statement.object as Activity;
  const items = activity.definition?.extensions![`http://${uriHost}/assessment`];
  const qs: any[] = items.questions;

  const url = apiCompositeUrl + "multiple";
  const refKeys = qs.map((x) => x.referenceKey);
  const questions: ItemQuestionExtended[] = await post(url, refKeys);

  const qStatementRequests: Promise<Statement | null>[] = qs
    .filter((x) => x.statementRef !== null)
    .map((x) => {
      return getXapiStatement(x.statementRef);
    });
  const qStatements = await Promise.all(qStatementRequests);

  let response: ResultOverview[] = [];

  qs.forEach((x) => {
    const s = qStatements.find((y) => y?.id === x.statementRef);
    const activity = s?.object as Activity;
    const info = activity?.definition?.extensions![`http://${uriHost}/question`] as Extensions;
    const q = questions.find((y) => y.id.versionId === x.referenceKey.versionId);

    response = [
      ...response,
      {
        referenceKey: q?.id,
        question: q,
        result: s?.result,
        correctResponse: info?.correctResponse,
        optionOrder: info?.optionOrder,
        title: getStemText(q!),
      },
    ];
  });

  return response;
};

const getStemText = (question: Item) => {
  const base = question.items.find((x) => x.type === ItemType.QuestionBase);
  const stem = base?.items.find((x) => x.type === ItemType.QuestionStem);
  const text: ItemText = stem?.items.find((x) => x.type === ItemType.Text) as ItemText;
  return text.value;
};

export { getProgressedLearningObjectivesByQuestions, createProgressReport, createTestReport, getResultOverview };
