import { InteractionStatus, IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import Box from "@mui/material/Box";
import { loginRequest } from "authConfig";
import { useEffect } from "react";

const Login = () => {
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    loginRedirect(inProgress, instance);
  }, [instance, inProgress]);

  return (
    <Box sx={{ height: "100vh", width: "100vw", textAlign: "center", paddingTop: "50vh" }}>
      Redirecting to Microsoft login...
    </Box>
  );
};

export default Login;

export const loginRedirect = async (inProgress: InteractionStatus, instance: IPublicClientApplication) => {
  if (inProgress !== InteractionStatus.None) return;

  await instance.handleRedirectPromise();
  await instance.loginRedirect(loginRequest).catch((e) => {
    console.log("Login redirect error", e);
  });
};
