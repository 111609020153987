import { useMsal } from "@azure/msal-react";
import { msalConfig } from "authConfig";

export enum LogoutReason {
  ForgotPassword = "forgotPassword",
  NoRoles = "noRoles",
}

/**
 *
 * @returns Logout from application and clear local- and session storage
 */
export const useLogout = () => {
  const { instance } = useMsal();

  const logout = async (reason?: LogoutReason) => {
    sessionStorage.clear();
    await instance.handleRedirectPromise();
    await instance.logoutRedirect({
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri + (reason ? `#${reason}` : ""),
    });
  };

  return { logout };
};
