import { PropsWithChildren, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRedirect } from "utils/helpers/msal/Login";

export const ProtectedRoutes = ({ children }: PropsWithChildren) => {
  const { instance, inProgress } = useMsal();
  const isLoggedIn = useIsAuthenticated();
  const navigate = useNavigate();

  console.log("ProtectedRoutes isAuthenticated", isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) return;
    if (process.env.NODE_ENV === "development") console.log("ProtectedRoutes not logged in");

    // Redirect to login page if entering the app from the root path
    if (window.location.pathname === "/") loginRedirect(inProgress, instance);

    // Redirect to logout otherwise
    navigate("/logout", { replace: true });
  }, [isLoggedIn, navigate, instance, inProgress]);

  if (!isLoggedIn) return null;

  if (children) return <>{children}</>;

  return <Outlet />;
};
