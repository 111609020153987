import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import { BasicLayout } from "layout";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { useAppContext } from "context/AppContext";
import { LogoutReason } from "hooks/useLogout";

export const Logout = () => {
  const theme = useTheme();
  const appContext = useAppContext();
  const location = useLocation();

  console.log("Logout location", location);

  const logo = appContext.themeName === "FM" ? "/img/logoFM.png" : "/img/logoFlex.png";
  const background =
    appContext.themeName === "FM" ? (
      <BackgroundShape top={0} left={0} bottom={0} right={0} position="fixed">
        <img src={process.env.PUBLIC_URL + "/img/camo.svg"} alt="Bakgrund" style={{ minWidth: "100%" }} />
      </BackgroundShape>
    ) : (
      <BackgroundShape top={0} left={0} bottom={0} right={0} position="fixed" background={theme.palette.primary.dark}>
        <></>
      </BackgroundShape>
    );

  return (
    <BasicLayout paddingBottom={0} paddingLeft={0} paddingRight={0} paddingTop={0}>
      {background}

      <Box height="100vh" display="flex" alignItems="center">
        <Stack
          spacing={3}
          ml="auto"
          mr="auto"
          display="flex"
          flexWrap="wrap"
          alignContent="center"
          justifyContent="center"
          width="100%"
          maxWidth="500px"
          p="2.5rem"
          boxShadow={theme.shadows[2]}
          borderRadius={1.5}
          sx={{ backgroundColor: theme.palette.common.white }}
        >
          <img
            src={process.env.PUBLIC_URL + logo}
            alt="Logo"
            style={{ maxHeight: "48px", display: "block", marginRight: "auto" }}
          />
          <Typography variant="h2">Du är utloggad</Typography>
          {location.hash === `#${LogoutReason.NoRoles}` ? (
            <Typography variant="body2" color="error" mt={2}>
              Du har inte rätt behörighet för att logga in i den här applikationen.
            </Typography>
          ) : (
            <Typography variant="body2" color="textSecondary" mt={2}>
              Du har loggats ut från din session. Vänligen stäng din browser för att säkerställa att du är utloggad.
            </Typography>
          )}

          <Button
            color="secondary"
            fullWidth
            size="large"
            variant="contained"
            component={Link}
            to="/login"
            sx={{ borderRadius: 0.5 }}
          >
            Logga in
          </Button>
          <Outlet />
        </Stack>
      </Box>
    </BasicLayout>
  );
};
