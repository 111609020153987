import { useState } from "react";
import { Drawer, IconButton, Stack } from "@mui/material";
import { Icon, iconArrowRightRegular, iconClose, iconHome, iconLogout, iconMenu } from "theme/icons";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { useAppIntl } from "services/useAppIntl";
import GroupList from "layout/educator/GroupList";
import { useLogout } from "hooks/useLogout";
import { Title } from "utils/helpers/TitleDivider";

const MainMenu = () => {
  const intl = useAppIntl();
  const [open, setOpen] = useState(false);
  const { logout } = useLogout();

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={toggleDrawer(true)}
      >
        <Icon name={iconMenu} />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <IconButton
          size="large"
          color="primary"
          aria-label="menu-close"
          onClick={toggleDrawer(false)}
          sx={{ mr: "auto" }}
        >
          <Icon name={iconClose} size="large" />
        </IconButton>

        <Stack width="75vw" height="100%" spacing={2} p={6} pt={2}>
          <NavigationItem
            text={intl.formatMessage({ id: "common.title.home" })}
            icon={iconHome}
            navigationIcon={iconArrowRightRegular}
            href={"/"}
            // dataTestIdString={testId.listItem.home.testTodaysQuestion}
          />

          <Title
            title={intl.formatMessage({ id: "common.title.educator" })}
            variant="h2"
            sx={{ mt: "2em !important" }}
          />
          <GroupList />

          <NavigationItem
            text={intl.formatMessage({ id: "common.logout" })}
            icon={iconLogout}
            onClick={async () => await logout()}
            sx={{ marginTop: "auto !important" }}
            // dataTestIdString={testId.listItem.home.testTodaysQuestion}
          />
        </Stack>
      </Drawer>
    </>
  );
};

export default MainMenu;
