import { ReactNode } from "react";
import { Box } from "@mui/material";

interface IBackgroundShape {
  top?: string | number;
  left?: string | number;
  bottom?: string | number;
  right?: string | number;
  center?: boolean;
  zIndex?: number;
  position?: "absolute" | "fixed";
  stickToBottom?: boolean;
  children: ReactNode;
  background?: string;
}

export const BackgroundShape = (props: IBackgroundShape) => {
  let left = props.left;
  let transform = undefined;
  const zIndex = props.zIndex ? props.zIndex : -1;
  const position = props.position ? props.position : "absolute";

  if (props.center) {
    left = "50%";
    transform = "translate(-50%, 0)";
  }

  const style = {
    position: position,
    top: props.top,
    left: left,
    bottom: props.bottom,
    right: props.right,
    transform: transform,
    zIndex: zIndex,
    background: props.background,
  };

  if (props.stickToBottom)
    return (
      <Box position="relative">
        <Box sx={style}>{props.children}</Box>
      </Box>
    );

  return <Box sx={style}>{props.children}</Box>;
};
