import { Box, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { FormattedTypography } from "./FormattedTypography";
const imgFailure = process.env.PUBLIC_URL + "/img/illustrations/test-failure.svg";

export const MaterialSkeleton = ({ title }: { title?: string }) => {
  return (
    <Box mb={3} px="16px" sx={{ backgroundColor: "#FFFFFF" }}>
      {title && <Typography variant="h1">{title}</Typography>}
      <Skeleton variant="rectangular" height={30} />
      <Skeleton variant="rectangular" height={50} />
      <Skeleton variant="rectangular" height={100} />
    </Box>
  );
};

export const AreasSkeleton = ({ title }: { title?: string }) => {
  const style = { borderRadius: "30px" };

  return (
    <Stack spacing={2}>
      {title && <Typography variant="h1">{title}</Typography>}
      <Skeleton variant="rounded" height={75} sx={style} />
      <Skeleton variant="rounded" height={75} sx={style} />
      <Skeleton variant="rounded" height={75} sx={style} />
    </Stack>
  );
};

export const UserFormSkeleton = () => {
  return (
    <>
      <Stack spacing={2} mb={2}>
        <Skeleton variant="rectangular" height={20} width={150} />
        <Skeleton variant="rectangular" height={50} />
        <Skeleton variant="rectangular" height={50} />
        <Skeleton variant="rectangular" height={50} />
      </Stack>
    </>
  );
};

export const FinalTestResultSkeleton = () => {
  return (
    <>
      <FormattedTypography variant="h1" mb={2} id="test.start.final" />

      <Box ml="auto" mr="auto" textAlign="center">
        <img src={imgFailure} height={"240px"} alt="Testresult" />
      </Box>
      <Stack spacing={1.5} mb={8} mt={2} display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Skeleton variant="rectangular" height={24} width={140} />
        <Skeleton variant="rectangular" height={24} width={50} />
        <Skeleton variant="rectangular" height={18} width={40} />
      </Stack>
    </>
  );
};

export const TestResultSkeleton = () => {
  return (
    <>
      <FormattedTypography variant="h1" id={"common.result-overview"} mb={2} />
      <Typography variant="subtitle1" mb={2}>
        ?/?
      </Typography>
      <Stack spacing={0} mb={2} mx={-2}>
        <TestResultItemSkeleton />
        <TestResultItemSkeleton />
        <TestResultItemSkeleton />
        <TestResultItemSkeleton />
        <TestResultItemSkeleton />
        <TestResultItemSkeleton />
        <TestResultItemSkeleton />
        <TestResultItemSkeleton />
        <TestResultItemSkeleton />
        <TestResultItemSkeleton />
      </Stack>
    </>
  );
};

const TestResultItemSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
      height="75px"
      px={2}
    >
      <Skeleton variant="circular" height={24} width={24} />
      <Skeleton variant="rectangular" height={20} width="80%" />
      <Skeleton variant="circular" height={24} width={24} />
    </Box>
  );
};

export const EducationCardSkeleton = () => {
  const theme = useTheme();
  const style = { borderRadius: `${theme.shape.borderRadius}px` };

  return (
    <Stack spacing={1.5} mt={3}>
      <Typography variant="subtitle1">Utbildningsinnehåll</Typography>
      <Skeleton variant="rounded" height={52} sx={style} />
      <Skeleton variant="rounded" height={52} sx={style} />
      <Skeleton variant="rounded" height={52} sx={style} />
      <Skeleton variant="rounded" height={52} sx={style} />
      <Skeleton variant="rounded" height={52} sx={style} />
      <Skeleton variant="rounded" height={52} sx={style} />
      <Skeleton variant="rounded" height={52} sx={style} />
      <Skeleton variant="rounded" height={52} sx={style} />
      <Skeleton variant="rounded" height={52} sx={style} />
      <Skeleton variant="rounded" height={52} sx={style} />
    </Stack>
  );
};
