import { Route, Routes } from "react-router-dom";
import { Unauthorized } from "layout/unauthorized";
import { Box } from "@mui/material";
import { SelectRole, Start } from "layout/start";
import useRoles from "hooks/useRoles";
import EducatorRoutes from "./educator";
import StudentRoutes from "./student";
import { ProtectedRoutes } from "./protectedRoutes";
import { EducatorRoleLayout } from "layout";
import { Logout } from "layout/logout";
import Login from "utils/helpers/msal/Login";

/**
 * Extracts the parent path from a given path string. (e.g. "/foo/bar" -> "/foo")
 *
 * @param path - The full path string from which to extract the parent path.
 * @returns The parent path string.
 */
export function getParentPath(path: string) {
  const paths = path.split("/");
  paths.splice(-1, 1);
  return paths.join("/");
}

export const DefaultRoutes = () => {
  const { isEducator, isStudent } = useRoles();

  if (isEducator && !isStudent) return <EducatorRoutes />;
  if (isStudent && !isEducator) return <StudentRoutes />;

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route element={<EducatorRoleLayout />}>
          <Route path="/select-role" element={<SelectRole />} />
        </Route>
      </Route>

      <Route path="/start" element={<Start />} />
      <Route path="/auth-redirect" element={<Box />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<Unauthorized />} />
    </Routes>
  );
};
